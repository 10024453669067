import React, {useEffect, useState} from "react";
import cronstrue from "cronstrue";

import useRequest from "../../hooks/useRequest";
import {commonFunction} from "../../components/common-functions";

import Head from "../../components/head";
import Header from "../../components/dashboard/header";
import DotMenu from "../../components/dashboard/dotmenu";
import SideNav from "../../components/dashboard/side-navbar";
import DeleteConfirmModal from "../../components/dashboard/delete-confirm";
import CreateEditAutomation from "../../components/dashboard/create-edit-automation";

import add from "../../static/svg/add.svg";
const {showAlert} = commonFunction;

const Automation = () => {

  const [automationId, setAutomationId] = useState("");
  const [allAutomation, setAllAutomation] = useState([]);
  const [eautomationData, setEautomationData] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  const [isAutomationEdit, setAutomationEdit] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showAutomationPopup, setAutomationPopup] = useState(false);

  const {data: automationData, loading, callAPI} = useRequest("api/v1/automations/");
  useEffect(() => {
    if(!loading && automationData) {
      const {data} = automationData;
      setAllAutomation(data);
    }
  }, [automationData, loading]);

  const {
    data: deleteData, loading: deleteLoading, callAPI: deleteCallAPI
  } = useRequest(`api/v1/automations/${automationId}/`, false, {}, "DELETE");
  useEffect(() => {
    if(!deleteLoading && deleteData) {
      showAlert("Successfully deleted the saved automation.");
      setAutomationId("");
      setDisabledButton(false);
      setShowDeleteConfirm(false);
      setTimeout(() => {
        callAPI();
      });
    }
  }, [deleteData, deleteLoading, callAPI]);

  const {
    data: editData, loading: editLoading, callAPI: editCallAPI
  } = useRequest(`api/v1/automations/${automationId}/`, false);
  useEffect(() => {
    if(editData && !editLoading){
      const {data} = editData;
      const {
        isScheduled, cronValue, 
        dbLimit, dbPassword, dbQuery, dbUrl, dbVendor, 
        name, template, markerMap, outputFileName, outputFormat, 
        isFTPEnabled, ftpPassword, ftpPath, ftpPort, ftpUrl, ftpUsername, outputType,
        fileNamePrefix, watermark, passwordColumn, printDocument, emailColumn, emailSubject, emailBody
      } = data;
      setEautomationData({
        one: {
          cronjobName: name,
          template
        },
        database: {
          dbLimit,
          dbPassword,
          dbQuery,
          dbUrl,
          dbVendor
        },
        mapData: {
          ...markerMap
        },
        scheduler: {
          cronValue,
          ftp: isFTPEnabled,
          outputType,
          password: ftpPassword,
          path: ftpPath,
          port: ftpPort,
          schedule: isScheduled,
          url: ftpUrl,
          username: ftpUsername
        },
        generation: {
          format: outputFormat,
          name: outputFileName,
          prefix: fileNamePrefix,
          watermark,
          passwordColumn
        },
        distribution: {
          printDocument,
          emailColumn,
          subject: emailSubject,
          body: emailBody
        }
      });
      setAutomationPopup(true);
    }
  }, [editData, editLoading]);

  const {
    data: manualGenerateData, loading: manualGenerateLoading, callAPI: callManualGenerateAPI
  } = useRequest(`api/v1/automations/${automationId}/`, false, {}, "POST");
  useEffect(() => {
    if(!manualGenerateLoading && manualGenerateData){
      const {isFTPEnabled} = manualGenerateData;
      const ftpMsg = "Once generated the output file will be available on your FTP server.";
      showAlert(`Document generation has been initiated successfully. ${isFTPEnabled ? ftpMsg : ""}`);
    }
  }, [manualGenerateData, manualGenerateLoading])

  return (
    <div>
      <Head customTitle={"Integrations"}/>
      <Header></Header>
      <SideNav index={10}></SideNav>
      <main className="dashboard-container integrations">
        <div className="main-container">
          <div className="body">
            <div className="flex-container">
              <div className="flexbox two-third left">
                <div className="row-heading text-uppercase">
                  <div className="flex-container vertical-middle">
                    <div className="flexbox name">
                      <span>Automation name</span>
                    </div>
                    <div className="flexbox view-type text-right">
                      Actions
                    </div>
                  </div>
                </div>

                <div className="list-container">
                  {
                    allAutomation.map(automation => {
                      const {_id, name, templateName, cronValue, isScheduled} = automation;
                      return (
                        <div className="data-row list" key={_id}>
                          <div className="flex-container vertical-middle">
                            <div className="icon-name automation">
                              <div className="details">
                                <span className="name">{name}</span>
                                <span className="date-size">{templateName}</span>
                              </div>
                            </div>
                            {isScheduled && 
                              <div className="flexbox cronvalue">
                                {cronstrue.toString(cronValue)}
                              </div>
                            }
                            <div className={`flexbox relative${isScheduled ? "" : " buttons"}`}>
                              {!isScheduled &&
                                <button
                                  className="btn"
                                  onClick={() => {
                                    if(!manualGenerateLoading){
                                      setAutomationId(_id);
                                      setTimeout(() => {
                                        callManualGenerateAPI();
                                      });
                                      return;
                                    }
                                    showAlert("One file generation request already in progress!", "error");
                                  }}
                                >Generate</button>
                              }
                              <DotMenu
                                submenus={[{
                                  title: "Edit Automation",
                                  click: () => {
                                    setAutomationId(_id);
                                    setAutomationEdit(true);
                                    setTimeout(() => {
                                      editCallAPI();
                                    });
                                  }
                                }, {
                                  title: "Delete...",
                                  click: () => {
                                    setAutomationId(_id);
                                    setShowDeleteConfirm(true);
                                  }
                                }]}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                  {
                    !loading && !allAutomation.length && (
                      <div className="data-row list">
                        <div className="flex-container vertical-middle">
                          <div className="flexbox text-center">
                            No automation present! Please create one.
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="flexbox one-third right">
                <button type="button" className="btn with-image moral full" onClick={() => setAutomationPopup(true)}>
                  <img src={add} className="btn-img" alt="icon"/>
                  Create automation
                </button>
              </div>
            </div>
          </div>
        </div>
        {showAutomationPopup &&
          <CreateEditAutomation
            isEdit={isAutomationEdit}
            automationId={automationId}
            initData={isAutomationEdit ? eautomationData : {}}
            close={() => {
              setAutomationPopup(false);
              if(isAutomationEdit){
                setAutomationEdit(false);
              }
            }}
            callbacks={() => {
              setAutomationPopup(false);
              callAPI();
            }}
          />
        }
        {showDeleteConfirm && 
          <DeleteConfirmModal
            disabeStatus={disabledButton}
            desc={"This will delete the selected automation, once deleted\
            you will not be able to recover it."}
            buttonText="Delete automation"
            disable={setDisabledButton}
            close={() => {
              setAutomationId("");
              setShowDeleteConfirm(false);
            }}
            doDelete={() => {
              deleteCallAPI();
            }}
          />
        }
      </main>
    </div>
  )
};

export default Automation;
