import React, {useEffect, useState, useCallback} from "react";

import MapFields from "./map-fields";
import StepOne from "./automation/step-one";
import DatabaseInput from "./database-input";
import Generation from "./automation/generation";
import AutomationDistribution from "./automation/distribution";
import ScheduleGeneration from "./automation/schedule-generation";

import useRequest from "../../hooks/useRequest";

import dltBtn from "../../static/svg/delete.svg";
import {steps} from "../configs/create-edit-automation.config";

const CreateEditAutomation = (props) => {
  const {close, callbacks, initData = {}, isEdit = false, automationId = ""} = props;

  const [payload, setPayload] = useState({});
  const [stepNumber, setStepNumber] = useState(0);
  const [stepData, setStepData] = useState(initData);

  const {data, loading, callAPI} = useRequest("api/v1/automations/", false, payload, "POST");
  useEffect(() => {
    if(!loading && data){
      callbacks();
    }
  }, [data, loading, callbacks]);

  const {
    data: editData, loading: editLoading, callAPI: callEditAPI
  } = useRequest(`api/v1/automations/${automationId}/`, false, payload, "PUT");
  useEffect(() => {
    if(!editLoading && editData){
      callbacks();
    }
  }, [editData, editLoading, callbacks]);

  useEffect(() => {
    if(payload && Object.keys(payload).length > 0){
      setTimeout(() => {
        if(isEdit){
          callEditAPI();
          return;
        }
        callAPI();
      })
    }
  }, [payload, isEdit, callAPI, callEditAPI]);

  const stepDataSetter = useCallback((key, keyData) => {
    if(key === "stepNumber"){
      if(keyData === 0){
        setStepNumber(0);
        return;
      }
      setStepNumber(2);
      return;
    }

    if(key){
      setStepData(data => ({
        ...data,
        [key]: {...keyData}
      }));
    }
  }, []);

  const createPayloadAndCallAPI = useCallback(() => {
    const {one, database, mapData, scheduler, generation, distribution} = stepData;

    const {cronjobName: name, template} = one;
    const {dbVendor, dbUrl, dbPassword, dbQuery, dbLimit} = database;
    const {
      ftp, schedule, cronValue, url: ftpUrl, port: ftpPort, path: ftpPath,
      username: ftpUsername, password: ftpPassword, outputType
    } = scheduler;
    const {
      format: outputFormat, name: outputFileName, 
      prefix: fileNamePrefix, watermark, passwordColumn
    } = generation;
    const {
      printDocument, emailColumn, subject: emailSubject, body: emailBody
    } = distribution;

    setPayload({
      name,
      template,
      dbVendor,
      dbUrl, 
      dbPassword, 
      dbQuery,
      dbLimit,
      markerMap: {
        ...mapData
      },
      isScheduled: schedule,
      ...(schedule ? {
        cronValue
      } : {}),
      isFTPEnabled: ftp,
      ...(ftp ? {
        ftpUrl,
        ftpPort,
        ftpUsername, 
        ftpPassword,
        ftpPath,
        outputType
      } : {}),
      outputFormat,
      outputFileName,
      fileNamePrefix,
      ...(outputFormat === "pdf" ? {
        watermark, passwordColumn
      } : {}),
      printDocument,
      ...(printDocument ? {
        emailColumn: "", 
        emailSubject: "", 
        emailBody: ""
      } : {
        emailColumn, emailSubject, emailBody
      })
    });
  }, [stepData]);
  
  const {title, moreDesc} = steps[stepNumber];
  
  return (
    <div className="full-page-overlay">
      <div className="flex-container full-height vertical-middle">
        <div className="big-modal">
          <div className="modal-body relative">
            <div className="close" onClick={close}>
              <img src={dltBtn} alt="close"/>  
            </div>
            <div className="flex-container">
              <div className="flexbox step">
                <div className="heading">Automation {isEdit ? "edit" : "creation"}</div>
                <p className="desc">{isEdit ? "Edit" : "Create"} automation by filling out the forms on each step</p>
                <ul className="steps">
                {
                  steps.map(({title, desc}, index) => (
                    <li
                      key={index + title}
                      className={
                        `relative${(index === stepNumber ? ' active' : '')}${(stepNumber > index ? ' done' : '')}`
                      }
                    >
                      <div className="step-heading">{`Step ${index+1}. ${title}`}</div>
                      <div className="btn-desc">{desc}</div>
                    </li>
                  ))
                }
                </ul>
              </div>
              <div className="flexbox action relative">
                <div className="main-heading">{`Step ${stepNumber+1}. ${title}`}</div>
                <div className="more-desc">{moreDesc}</div>
                {stepNumber === 0 &&
                  <StepOne
                    setStep={setStepNumber}
                    stepData={stepData.one || {}}
                    callback={stepDataSetter}
                    skey="one"
                  />
                }
                {stepNumber === 1 &&
                  <DatabaseInput
                    showback={true}
                    callback={stepDataSetter}
                    data={stepData.database || {}}
                    skey="database"
                  />
                }
                {stepNumber === 2 &&
                  <MapFields
                    callback={(key, keyData) => {
                      if(key === "stepNumber"){
                        if(keyData === 0){
                          setStepNumber(1);
                          return;
                        }
                        setStepNumber(3);
                        return;
                      }

                      setStepData(data => ({
                        ...data,
                        [key]: {...keyData}
                      }));
                    }}
                    skey="mapData"
                    headers={
                      stepData.database && stepData.database.headers ? stepData.database.headers : {}
                    }
                    markers={
                      stepData.one && stepData.one.markers ? stepData.one.markers : {}
                    }
                    data={stepData.mapData || {}}
                  />
                }
                {stepNumber === 3 &&
                  <ScheduleGeneration
                    setStep={setStepNumber}
                    stepData={stepData.scheduler || {}}
                    callback={stepDataSetter}
                    skey="scheduler"
                  />
                }
                {stepNumber === 4 &&
                  <Generation
                    markers={
                      stepData.one && stepData.one.markers ? stepData.one.markers : {}
                    }
                    setStep={setStepNumber}
                    stepData={stepData.generation || {}}
                    callback={stepDataSetter}
                    skey="generation"
                  />
                }
                {stepNumber === 5 &&
                  <AutomationDistribution
                    headers={
                      stepData.database && stepData.database.headers ? stepData.database.headers : {}
                    }
                    isEdit={isEdit}
                    setStep={setStepNumber}
                    stepData={stepData.distribution || {}}
                    callback={stepDataSetter}
                    skey="distribution"
                    submit={createPayloadAndCallAPI}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default CreateEditAutomation;
